.homeBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 60px;
  background-color: var(--main-bg-color);
  color: var(--main-text-color);
  font-size: 16px;
  max-width: 700px;
  height: 100vh;
}

.homeTitle {
  color: var(--main-text-color);
  padding-top: 20px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.HeroImg {
  width: 100%;
  max-width: 500px;
  height: auto;
}

.BuyNow,
.BuyNow:visited {
  color: whitesmoke;
  text-decoration: none;
}

.ReadMore {
  color: rgb(231, 154, 12);
  padding-top: 20px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
}

.ReadMoreButton {
  border: 2px solid rgb(231, 154, 12);
  height: 45px;
  margin-top: 20px;
  font-size: 16px;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  background: transparent;
  border-radius: 4px;
  background-color: transparent;
  font-weight: 500;
  text-transform: none;
  cursor: pointer;
  transition: 0.5s ease;
}
