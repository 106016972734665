@import url("https://fonts.googleapis.com/css2?family=Kantumruy+Pro:wght@200;300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700&display=swap");
@import "./css/navigation.css";
@import "./css/itemCard.css";
@import "./css/about.css";
@import "./css/home.css";
@import "./css/contact.css";
@import "./css/cartitem.css";
@import "./css/products.css";

:root {
  --main-text-color: rgb(25, 25, 25);
  --main-text-contast-color: rgb(245, 245, 245);
  --alt-text-color-1: rgb(69, 69, 69);
  --main-bg-color: rgb(255, 255, 255);
  --cart-bg-color: rgb(255, 255, 255);
  --highlight-bg-color: rgb(231, 231, 231);
  --unheat-color: rgb(212, 212, 212);
}

* {
  /*  font-family: "Kantumruy Pro", sans-serif; */
  /* font-family: "Karla", sans-serif; */
  font-family: "Source Sans 3", sans-serif;

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  background-color: var(--main-bg-color);
}

.App {
  background-color: var(--main-bg-color);
  text-align: center;
  /* background-color: #61dafb; */
}
