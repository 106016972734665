.aboutBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 60px;
  background-color: var(--main-bg-color);
  color: var(--main-text-color);
  font-size: 16px;
  max-width: 700px;
  height: 100vh;
}

h1 {
  color: var(--main-text-color);
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 20px;
}
