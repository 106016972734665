.itemCard {
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 95%;
  /* border: 1px solid red; */
}

.splideWrapper {
  background-color: transparent;
}

.itemImage {
  width: 500px;
  max-width: 100%;
  height: auto;
}

.itemBlurb {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.itemTitle {
  color: var(--main-text-color);
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
}

.itemHeat {
  font-family: "bootstrap-icons";
  display: flex;
  color: var(--main-text-color);
  font-size: 18px;
}

.heat {
  color: var(--main-text-color);
  font-size: 18px;
}

.unheat {
  color: var(--unheat-color);
  font-size: 18px;
}

.itemDetails {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.itemDesc,
.itemPrice,
.itemSize {
  color: var(--main-text-color);
  text-transform: uppercase;
  font-size: 14px;
}

/* ACCORDIAN STUFF */

.accordianWrapper {
  margin-top: 10px;
  width: 100%;
}

.accordion {
  background-color: transparent;
  margin-top: 3px;
  border: 1px solid var(--unheat-color);
  border-radius: 2px;
  color: var(--main-text-color);
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  text-align: left;
  outline: none;
  font-size: 14px;
  transition: 0.4s;
}

.accordion p {
  margin-left: 10px;
  padding: 0px;
}

.panel {
  max-width: 500px;
  max-height: 500px;
  width: 100%;
  overflow: hidden;
  text-transform: none;
}

.panel p {
  color: var(--main-text-color);
  text-align: left;
  font-size: 14px;
  text-transform: none;
  padding: 10px;
}
/* END ACCORDIAN STUFF */

.addToCart {
  border: none;
  height: 45px;
  margin-top: 20px;
  font-size: 16px;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  background: #be1111da;
  border-radius: 4px;
  box-shadow: 0 4px 6px -4px rgb(0, 0, 0, 0.5);
  letter-spacing: 1px;
  color: whitesmoke;
  margin-bottom: 20px;
  border: none;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.5s ease;
  display: block;
}

.addToCart:hover {
  background: #df0b0b;
}
