.contactwrapper {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 50%;
  /*  background: red; */
  height: fit-content;
  vertical-align: top;
  justify-content: center;
  gap: 20px;
}

.contactimage .contactBody {
  flex-grow: 1;
}

.contactimage {
}

.contactimage img {
  margin-top: 40px;
  height: auto;
  width: 100%;
  object-fit: contain;
}

.contactBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 60px;
  background-color: var(--main-bg-color);
  color: var(--main-text-color);
  font-size: 16px;
  max-width: 700px;
  height: 100vh;
}

.contactBlurb {
  padding-bottom: 10px;
}

.container {
  border-radius: 4px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  /* background-color: var(--highlight-bg-color); */
}

.contactForm {
  display: flex;
  flex-direction: column;
}

.contactForm input {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  padding-left: 5px;
  height: 2rem;
}

.contactForm input::placeholder {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  opacity: 0.65;
}

.contactForm textarea::placeholder {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  opacity: 0.65;
}

.contactForm textarea {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  padding-left: 5px;
  min-height: 100px;
  max-height: fit-content;
}

.formLabel {
  padding: 10px 0;
}

.submit {
  border: none;
  height: 45px;
  margin-top: 20px;
  font-size: 16px;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  background: #be1111da;
  border-radius: 4px;
  box-shadow: 0 4px 6px -4px rgb(0, 0, 0, 0.5);
  letter-spacing: 1px;
  color: whitesmoke;
  margin-bottom: 20px;
  border: none;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.5s ease;
  width: fit-content;
}

.submit:hover {
  background: #df0b0b;
}

.formBlurb {
  padding-bottom: 20px;
}

#name::placeholder {
  background-image: url(../assets/YourName.svg);
  background-repeat: no-repeat;
  background-position-x: 5px;
  background-size: auto 1.25rem;
  background-position-y: 60%;
}

#name::-webkit-input-placeholder {
  background-image: url(../assets/YourName.svg);
  background-repeat: no-repeat;
  background-position-x: 5px;
  background-size: auto 1.25rem;
  background-position-y: 60%;
}

#name::-moz-placeholder {
  background-image: url(../assets/YourName.svg);
  background-repeat: no-repeat;
  background-position-x: 5px;
  background-size: auto 1.25rem;
  background-position-y: 60%;
}

#email::placeholder {
  background-image: url(../assets/YourEmail.svg);
  background-repeat: no-repeat;
  background-position-x: 5px;
  background-size: auto 1.25rem;
  background-position-y: 60%;
}

#email::-webkit-input-placeholder {
  background-image: url(../assets/YourEmail.svg);
  background-repeat: no-repeat;
  background-position-x: 5px;
  background-size: auto 1.25rem;
  background-position-y: 60%;
}

#email::-moz-placeholder {
  background-image: url(../assets/YourEmail.svg);
  background-repeat: no-repeat;
  background-position-x: 5px;
  background-size: auto 1.25rem;
  background-position-y: 60%;
}

#message::placeholder {
  background-image: url(../assets/YourMessage.svg);
  background-repeat: no-repeat;
  background-position-x: 5px;
  background-size: auto 1.25rem;
  background-position-y: 5px;
}

#message::-webkit-input-placeholder {
  background-image: url(../assets/YourMessage.svg);
  background-repeat: no-repeat;
  background-position-x: 5px;
  background-size: auto 1.25rem;
  background-position-y: 5px;
}

#message::-moz-placeholder {
  background-image: url(../assets/YourMessage.svg);
  background-repeat: no-repeat;
  background-position-x: 5px;
  background-size: auto 1.25rem;
  background-position-y: 5px;
}

@media screen and (max-width: 1600px) {
  .contactimage {
    display: none;
  }
}

@media screen and (max-width: 811px) {
  .contactwrapper {
    width: 100%;
  }

  #message::placeholder {
    background-position-y: 2px;
  }

  #message::-webkit-input-placeholder {
    background-position-y: 2px;
  }

  #message::-moz-placeholder {
    background-position-y: 2px;
  }
}
