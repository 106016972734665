@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.2/font/bootstrap-icons.css");
/* HEADER */
.emptyMessage {
  color: var(--main-text-color);
}

.checkout {
  border: none;
  height: 45px;
  margin-top: 20px;
  font-size: 16px;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  background: #be1111da;
  border-radius: 4px;
  box-shadow: 0 4px 6px -4px rgb(0, 0, 0, 0.5);
  letter-spacing: 1px;
  color: whitesmoke;
  margin-bottom: 20px;
  border: none;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.5s ease;
  display: block;
}

.logo {
  display: block;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  height: 125px;
  padding-top: 20px;
  padding-bottom: 10px;
  background-color: var(--main-bg-color);
  /* background-color: yellow; */
}
/* END HEADER */

/* DESKTOP NAV START */
.headerWrap {
  background-color: var(--main-bg-color);
}

.fixedNav {
  /* background-color: red; */
  margin-left: auto;
  margin-right: auto;
}

#desktopbulb,
#desktopcart {
  color: var(--main-text-color);
  font-size: 16px;
  cursor: pointer;
}

.navlinks {
  /* background-color: purple; */
  list-style: none;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 35%;
  justify-content: space-around;
  text-transform: uppercase;
}

.navlinks li {
  color: var(--main-text-color);
  font-size: 16px;
  font-weight: 500;
}

.fixednav {
  color: var(--main-text-color);
  text-decoration: none;
}

.iconholder {
  display: flex;
  gap: 10px;
}

.headerIcon {
  color: rgb(29, 29, 29);
  font-size: 18px;
}

/* DESKTOP CART */
.cartOpen {
  display: block;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  transform: none;
}

.cartClosed {
  display: none;
}

#cartCloseMobile {
  position: absolute;
  margin-top: 20px;
  right: 20px;
}

#cartContents {
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 8px 10px -4px rgb(0, 0, 0, 0.45);
  z-index: 1000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 90%;
  width: 500px;
  background-color: var(--main-bg-color);
  overflow-y: auto;
}

.cartIconAssyDesk {
  display: flex;
}

.cartCountDesk {
  background-color: #be1111;
  color: whitesmoke;
  border-radius: 4px;
  position: relative;
  box-sizing: border-box;
  padding: 0 3px;
  top: -10px;
  right: 7px;
  text-align: center;
  width: fit-content;
  min-width: 16px;
  height: 18px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media screen and (max-width: 1201px) {
  .navlinks {
    width: 55%;
  }
}

@media screen and (max-width: 815px) {
  .fixedNav {
    display: none;
  }

  .cartIconAssyMobile {
    display: flex;
  }

  .cartCountMobile {
    background-color: #be1111;
    color: whitesmoke;
    border-radius: 4px;
    position: absolute;
    box-sizing: border-box;
    padding: 0 3px;
    top: -7px;
    right: 14px;
    text-align: center;
    width: fit-content;
    min-width: 16px;
    height: 18px;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  #cartContents {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 8px 10px -4px rgb(0, 0, 0, 0.45);
    z-index: 1000;
    position: fixed;
    top: 5%;
    left: 10%;
    height: 90%;
    width: 80%;
    background-color: var(--main-bg-color);
    transform: none;
  }
}

@media screen and (max-width: 450px) {
  .fixedNav {
    display: none;
  }
  .logo {
    width: 85%;
    height: auto;
  }

  #cartContents {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 8px 10px -4px rgb(0, 0, 0, 0.45);
    z-index: 1000;
    position: absolute;
    top: 5%;
    left: 5%;
    height: 90%;
    width: 90%;
    background-color: var(--main-bg-color);
    transform: none;
  }
}
/* DESKTOP NAV END */

/* BURGER MENU START */
.mobilenav {
  position: absolute;
  display: flex;
  overflow-x: hidden;
  padding-top: 6px;
  margin-top: -20px;
  display: flex;
  justify-content: space-between;
  height: fit-content;
}

.hamburger-menu {
  display: flex;
  position: relative;
}

#navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  background-color: var(--main-bg-color);
}

.headerIconMobile {
  color: var(--main-text-color);
  font-size: 24px;
  cursor: pointer;
}

#mobilebulb,
#mobilecart,
#cartCloseMobile {
  color: var(--main-text-color);
  font-size: 24px;
  cursor: pointer;
}

.iconHolderMobile {
  margin-right: 20px;
  gap: 5px;
  display: flex;
  justify-content: space-between;
}

.burgSpan {
  background-color: var(--main-text-color);
  color: transparent;
  border-radius: 2px;
  /* box-shadow: 0 2px 6px 0 var(--main-text-contast-color); */
}

.menu__box {
  background-color: var(--main-bg-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  left: -100%;
  width: 100%;
  height: fit-content;
  margin: 0;
  padding: 20px 0 10px 0;
  list-style: none;
  box-shadow: 0px 8px 10px -4px rgb(0, 0, 0, 0.45);
  transition-duration: 0.5s;
  z-index: 1;
}

.menu__item1,
.menu__item2,
.menu__item3,
.menu__item4 {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  text-align: center;
  padding: 16px 24px;
  color: var(--main-text-color);
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  text-decoration: none;
  width: 225px;
  /* border: 1px solid black; */
}

.menu__toggle {
  display: none;
  margin-left: 0;
  z-index: 100;
}

.menu__toggle:checked ~ .menu__box {
  left: 0 !important;
}

.menu__toggle:checked ~ .menu__box /* .menu__item */ {
  animation-name: animateIn;
  animation-duration: 0.5s;
  animation-delay: calc(var(--animation-order) * 250ms);
  animation-fill-mode: both;
  animation-timing-function: ease;
}

.menu__toggle:checked ~ .menu__box .menu__item1 {
  animation-name: animateIn2;
  animation-duration: 0.5s;
  animation-delay: 250ms;
  animation-fill-mode: both;
  animation-timing-function: ease;
}

.menu__toggle:checked ~ .menu__box .menu__item2 {
  animation-name: animateIn2;
  animation-duration: 0.5s;
  animation-delay: 500ms;
  animation-fill-mode: both;
  animation-timing-function: ease;
}

.menu__toggle:checked ~ .menu__box .menu__item3 {
  animation-name: animateIn2;
  animation-duration: 0.5s;
  animation-delay: 750ms;
  animation-fill-mode: both;
  animation-timing-function: ease;
}

.menu__toggle:checked ~ .menu__box .menu__item4 {
  animation-name: animateIn2;
  animation-duration: 0.5s;
  animation-delay: 1000ms;
  animation-fill-mode: both;
  animation-timing-function: ease;
}

/* BURGER MENU END */

.box {
  margin-left: 20px;
  border-radius: 2px;
  width: 26px;
  height: 24px;
  background: transparent;
  z-index: 4;
}

.btn {
  width: 26px;
  height: 24px;
  cursor: pointer;
  background-color: transparent;
  z-index: 3;
}

span {
  display: block;
  width: 100%;
  border-radius: 1px;
  height: 3px;
  transition: all 0.3s;
  position: relative;
  z-index: 3;
}

span + span {
  margin-top: 6px;
}

.active span:nth-child(1) {
  animation: ease 0.7s top forwards;
}

.not-active span:nth-child(1) {
  animation: ease 0.7s top-2 forwards;
}

.active span:nth-child(2) {
  animation: ease 0.7s scaled forwards;
}

.not-active span:nth-child(2) {
  animation: ease 0.7s scaled-2 forwards;
}

.active span:nth-child(3) {
  animation: ease 0.7s bottom forwards;
}

.not-active span:nth-child(3) {
  animation: ease 0.7s bottom-2 forwards;
}

@keyframes top {
  0% {
    top: 0;
    transform: rotate(0);
  }
  50% {
    top: 10px;
    transform: rotate(0);
  }
  100% {
    top: 10px;
    transform: rotate(45deg);
  }
}

@keyframes top-2 {
  0% {
    top: 10px;
    transform: rotate(45deg);
  }
  50% {
    top: 10px;
    transform: rotate(0deg);
  }
  100% {
    top: 0;
    transform: rotate(0deg);
  }
}

@keyframes bottom {
  0% {
    bottom: 0;
    transform: rotate(0);
  }
  50% {
    bottom: 8px;
    transform: rotate(0);
  }
  100% {
    bottom: 8px;
    transform: rotate(135deg);
  }
}

@keyframes bottom-2 {
  0% {
    bottom: 8px;
    transform: rotate(135deg);
  }
  50% {
    bottom: 8px;
    transform: rotate(0);
  }
  100% {
    bottom: 0;
    transform: rotate(0);
  }
}

@keyframes scaled {
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes scaled-2 {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes animateIn {
  0% {
    opacity: 1;
    transform: translateX(-100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes animateIn2 {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@media screen and (min-width: 815px) {
  .hamburger-menu {
    display: none;
  }
}
