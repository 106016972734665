.cart_item {
  border-radius: 4px;
  display: flex;
  border: 1px solid gray;
  padding: 10px;
  width: 90%;
  height: fit-content;
}

#prod_details {
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
}

.item_title {
  text-transform: none;
  font-weight: 500;
  color: var(--main-text-color);
}

.sauce {
  height: 100px;
  width: auto;
  padding: 0;
}

.item_price,
.item_size {
  font-size: 16px;
  font-weight: 400;
  color: var(--main-text-color);
}

#trash_item {
  color: var(--main-text-color);
}

.quantityInput {
  display: flex;
  gap: 3px;
  vertical-align: middle;
  height: fit-content;
}

.inputAssy {
  width: fit-content;
  margin-left: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: fit-content;
  gap: 3px;
}

.inputAssy i {
  line-height: 1rem;
  align-self: center;
  font-size: 20px;
  color: var(--main-text-color);
}

.numInput {
  border: 2px solid gray;
  border-radius: 3px;
  font-weight: 400;
  font-family: "Source Sans 3", sans-serif;
  height: auto;
  font-size: 16px;
  width: 40px;
  text-align: center;
  box-sizing: border-box;
}

.quantityBox {
  border: 2px solid gray;
  border-radius: 3px;
  font-weight: 400;
  font-family: "Source Sans 3", sans-serif;
  height: auto;
  font-size: 16px;
  width: 32px;
  text-align: center;
  box-sizing: border-box;
  color: var(--main-text-color);
}

.numInput textarea {
  line-height: 1.4rem;
}

#cart_remove {
  flex-grow: 2;
  text-align: right;
}

.cartItemsDisplay {
  margin-top: 60px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.subtotalLine {
  padding-top: 20px;
  width: 75%;
  display: inline-flex;
  justify-content: space-between;
  color: var(--main-text-color);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
/* input[type="number"] {
  -moz-appearance: textfield;
} */
