.productList {
  width: 1000px;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  gap: 0px;
  align-self: center;
  padding: 0;
}

@media screen and (max-width: 1000px) {
  .productList {
    width: 100%;
    grid-template-columns: auto;
  }
}
